export default {
  data() {
    return {
      tab: 0,
      reportDate: null,
      cashSales: null,
      sharedAccountId: null,
      lunchSales: null,
      dinnerSales: null,
      confirm: false,
      isEdit: false,
      serverLunchSale: false,
      serverDinnerSale: false,
      isReported: 0,
      lunchSalesInput: [
        //   food sales 0
        {
          label: "フード売上",
          type: "currency",
          name: "food_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:フード売上|enter_half_width_amount"
        },
        // Drink sales 1
        {
          label: "ドリンク売上",
          type: "currency",
          name: "drink_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:ドリンク売上|enter_half_width_amount"
        },
        // Delivery sales 2
        {
          label: "デリバリー売上",
          type: "currency",
          name: "delivery_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:デリバリー売上|enter_half_width_amount"
        },
        // number_of_delivery 3
        {
          label: "&nbsp;",
          type: "number",
          name: "number_of_delivery",
          placeholder: "0",
          value: 0,
          rules: "required:デリバリー件数",
          append: "件",
          width: "30%"
        },
        // Takeaway sales 4
        {
          label: "テイクアウト売上",
          type: "currency",
          name: "takeout_unit_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:テイクアウト売上|enter_half_width_amount"
        },
        // 5
        {
          label: "&nbsp;",
          type: "number",
          name: "number_of_takeout",
          placeholder: "0",
          value: 0,
          rules: "required:テイクアウト件数",
          append: "件",
          width: "30%"
        },
        // Other sales 6
        {
          label: "その他売上",
          type: "currency",
          name: "others_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:その他売上|enter_half_width_amount"
        },
        // Number of customers in the store 7
        {
          label: "店内利用客数",
          type: "number",
          name: "number_of_customer_store",
          placeholder: "0",
          value: 0,
          rules: "required:店内利用客数",
          width: "45%",
          append: "人"
        },
        // Total number of customers 8
        {
          label: "総客数",
          type: "number",
          name: "number_of_total_customer",
          placeholder: "0",
          value: 0,
          rules: "required:総客数",
          width: "45%",
          append: "人"
        },
        // Number of groups used in the store 9
        {
          label: "店内利用組数",
          type: "number",
          name: "number_of_group_store",
          placeholder: "0",
          value: 0,
          rules: "required:店内利用組数",
          width: "45%",
          append: "組"
        },
        // Total number of pairs 10
        {
          label: "総組数",
          type: "number",
          name: "number_of_total_group",
          placeholder: "0",
          value: 0,
          rules: "required:総組数",
          width: "45%",
          append: "組"
        },
        // Total part-time job fee 11
        {
          label: "合計アルバイト代",
          type: "currency",
          name: "payment",
          placeholder: "￥0",
          value: 0,
          rules: "required:合計アルバイト代|enter_half_width_amount",
          width: "100%"
        },
        // weather 12
        {
          label: "主な天気",
          type: "btnGroup",
          value: undefined,
          width: "100%",
          name: "weather"
        },
        // Description / Memo 13
        {
          label: "報告",
          type: "textarea",
          placeholder: "自由入力",
          name: "notes",
          value: "",
          rules: "",
          width: "100%"
        },
        {
          isReported: 0,
          id: null
        }
      ],
      daySalesInput: [
        //   food sales 0
        {
          label: "フード売上",
          type: "currency",
          name: "food_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:フード売上|enter_half_width_amount"
        },
        // Drink sales 1
        {
          label: "ドリンク売上",
          type: "currency",
          name: "drink_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:ドリンク売上|enter_half_width_amount"
        },
        // Delivery sales 2
        {
          label: "デリバリー売上",
          type: "currency",
          name: "delivery_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:デリバリー売上|enter_half_width_amount"
        },
        // number_of_delivery 3
        {
          label: "&nbsp;",
          type: "number",
          name: "number_of_delivery",
          placeholder: "0",
          value: 0,
          rules: "required:デリバリー件数",
          append: "件",
          width: "30%"
        },
        // Takeaway sales 4
        {
          label: "テイクアウト売上",
          type: "currency",
          name: "takeout_unit_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:テイクアウト売上|enter_half_width_amount"
        },
        // 5
        {
          label: "&nbsp;",
          type: "number",
          name: "number_of_takeout",
          placeholder: "0",
          value: 0,
          rules: "required:テイクアウト件数",
          append: "件",
          width: "30%"
        },
        // Other sales 6
        {
          label: "その他売上",
          type: "currency",
          name: "others_sales",
          placeholder: "￥0",
          value: 0,
          rules: "required:その他売上|enter_half_width_amount"
        },
        // Number of customers in the store 7
        {
          label: "店内利用客数",
          type: "number",
          name: "number_of_customer_store",
          placeholder: "0",
          value: 0,
          rules: "required:店内利用客数",
          width: "45%",
          append: "人"
        },
        // Total number of customers 8
        {
          label: "総客数",
          type: "number",
          name: "number_of_total_customer",
          placeholder: "0",
          value: 0,
          rules: "required:総客数",
          width: "45%",
          append: "人"
        },
        // Number of groups used in the store 9
        {
          label: "店内利用組数",
          type: "number",
          name: "number_of_group_store",
          placeholder: "0",
          value: 0,
          rules: "required:店内利用組数",
          width: "45%",
          append: "組"
        },
        // Total number of pairs 10
        {
          label: "総組数",
          type: "number",
          name: "number_of_total_group",
          placeholder: "0",
          value: 0,
          rules: "required:総組数",
          width: "45%",
          append: "組"
        },
        // Total part-time job fee 11
        {
          label: "合計アルバイト代",
          type: "currency",
          name: "payment",
          placeholder: "￥0",
          value: 0,
          rules: "required:合計アルバイト代|enter_half_width_amount",
          width: "100%"
        },
        // weather 12
        {
          label: "ディナー時の主な天気",
          type: "btnGroup",
          value: undefined,
          width: "100%",
          name: "weather"
        },
        // Description / Memo 13
        {
          label: "報告",
          type: "textarea",
          placeholder: "自由入力",
          name: "notes",
          value: "",
          rules: "",
          width: "100%"
        },
        {
          isReported: 0,
          id: null
        }
      ]
    };
  },
  methods: {
    dinnerSaleOnEdit(selectedOperator = "+") {
      var operators = {
        "+": function(a, b) {
          return a + b;
        },
        "-": function(a, b) {
          return a - b;
        }
      };

      this.lunchSalesInput.forEach((item, index) => {
        if (
          (item.type == "number" || item.type == "currency") &&
          item.value > 0 &&
          this.daySalesInput[index].value > 0
          ) {
          this.daySalesInput[index].value = operators[selectedOperator](
            this.daySalesInput[index].value,
            item.value
          );
        }
      });
    },
    calculateDifference() {
      for (let item in this.lunchSales) {
        if (
          !this.dinnerSales[item] ||
          !this.lunchSales[item] ||
          item === "notes" ||
          item === "weather" ||
          item === "lunch_or_dinner"
        ) {
          continue;
        }

        this.dinnerSales[item] = Math.abs(
          parseInt(this.dinnerSales[item]) - parseInt(this.lunchSales[item])
        );
      }
    },
    storeDaySales() {
      // lets prepare data
      const params = {};
      this.daySalesInput.forEach(field => {
        if (field?.name && field.name !== "") {
          params[field.name] = field.value;
        }
      });
      this.dinnerSales = params;
    },
    storeLunchReport() {
      // lets prepare data
      const params = {};
      this.lunchSalesInput.forEach(field => {
        if (field?.name && field.name !== "") {
          params[field.name] = field.value;
        }
      });
      this.lunchSales = params;
    },
    submitSalesForm() {
      if (this.tab == "0") {
        this.$refs.lunchSalesForm.$refs.observer.validate().then(success => {
          if (!success) {
            return;
          }

          if (this.isEdit) {
            // Change Dinner Inside Validations
            this.dinnerSaleOnEdit();
          }

          this.storeLunchReport();
          this.storeDaySales();
          this.calculateDifference();
          this.confirm = true;
        });
      } else {
        this.$refs.daySaleForm.$refs.observer.validate().then(success => {
          if (!success) {
            return;
          }

          if (this.isEdit) {
            // Change Dinner Inside Validations
            this.dinnerSaleOnEdit();
          }

          this.storeLunchReport();
          this.storeDaySales();
          this.calculateDifference();
          this.confirm = true;
        });
      }
    },
    closeConfirm() {
      if (this.isEdit) {
        this.dinnerSaleOnEdit("-"); // revert addition.
      }
      this.confirm = false;
    }
  }
};
