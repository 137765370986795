<template>
  <v-container v-if="!confirm" class="bg-color-theme px-3">
    <v-skeleton-loader
      v-if="pageLoading"
      type="list-item-two-line, card"
    ></v-skeleton-loader>
    <template v-else>
      <h1 class="text-center font-18px my-3 fw-400 input-form">
        {{ reportDate.format("YYYY年MM月DD日") }}
      </h1>
      <v-tabs
        v-model="tab"
        grow
        height="40px"
        class="font-12px mt-5"
        hide-slider
        background-color="#E5E5E5"
        color="#898989"
      >
        <v-tab class="font-12px elevation-1" active-class="tab-active">
          ランチ売上
        </v-tab>
        <v-tab class="font-12px elevation-1" active-class="tab-active-blue">
          {{ isDinnerSales ? "ディナー売上" : "当日売上" }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent">
        <v-tab-item>
          <DrForm
            :cashsale.sync="cashSales"
            :sharedAccountId.sync="sharedAccountId"
            :isEdit="isEdit"
            :fields="lunchSalesInput"
            :isReported="isReported"
            :infoModal="{
              component: 'LunchSalesModal',
              tab_text: 'ランチ売上の入力について'
            }"
            ref="lunchSalesForm"
            :deleteCb="function() {}"
            :submitCb="submitSalesForm"
          ></DrForm>
        </v-tab-item>
        <v-tab-item>
          <DrForm
            :cashsale.sync="cashSales"
            :sharedAccountId.sync="sharedAccountId"
            :isEdit="isEdit"
            :fields="daySalesInput"
            :deleteCb="function() {}"
            :isReported="isReported"
            ref="daySaleForm"
            :submitCb="submitSalesForm"
            :infoModal="{
              component: 'SameDayModal',
              tab_text: '当日売上の入力について'
            }"
          ></DrForm>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-container>
  <v-container v-else>
    <DrConfirm
      v-bind="{
        dinnerSales,
        lunchSales,
        submitReport,
        closeConfirm,
        cashSales,
        sharedAccountId
      }"
      :deleteCb="function() {}"
      :isReported="isReported"
      :reportDate="reportDate"
      :loading="pageLoading"
    ></DrConfirm>
  </v-container>
</template>

<script>
import DrForm from "./DrForm";
import { mapActions, mapGetters } from "vuex";
import DrConfirm from "./DrConfirm.vue";
import dailyReportMixin from "./dailyReportMixin";
import dayjs from "@/plugins/dayjs";

export default {
  name: "DrInput",
  mixins: [dailyReportMixin],
  components: { DrForm, DrConfirm },
  data() {
    return {
      pageLoading: true,
      isReported: 0,
      isDinnerSales: false
    };
  },
  created() {
    // get the date
    this.reportDate = dayjs(this.$router.currentRoute.params.date);
  },
  mounted() {
    this.$store
      .dispatch("GET_REPORT_DETAILS_BY_DATE", {
        shop_id: this.getShop.id,
        date: this.reportDate.format("YYYY-MM-DD")
      })
      .then(() => {
        let lunchData = this.getReportDetails?.lunch_data;
        let dinnerData = this.getReportDetails?.dinner_data;

        this.isEdit = false;
        this.isDinnerSales = dinnerData ? true : false;
        if (lunchData || dinnerData) {
          this.isEdit = true;
          if (!lunchData || !dinnerData) {
            this.isEdit = false;
          }
          this.setPageData(lunchData, dinnerData);
        }
      })
      .finally(() => {
        this.pageLoading = false;
      });
  },
  computed: {
    ...mapGetters(["getReportDetails"]),
    getShop() {
      return this.$store.getters.getFirstShopObject;
    }
  },
  methods: {
    ...mapActions({
      updateDailyReportForm: "UPDATE_DAILY_REPORT_FORM"
    }),
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },
    setPageData(lunchData, dinnerData) {
      this.lunchSalesInput.forEach(input => {
        if (input.name == "weather") {
          input.value = parseInt(lunchData?.[input.name]);
        } else if (input?.name && input?.name !== "") {
          input.value = lunchData?.[input.name] ? lunchData[input.name] : 0;
          if (input?.name == "notes") {
            input.value = lunchData[input.name] ? lunchData[input.name] : "";
          }
        }
      });

      this.daySalesInput.forEach(input => {
        if (input.name == "weather") {
          input.value = parseInt(dinnerData?.[input.name]);
        } else if (input?.name && input?.name !== "") {
          if (input?.name == "notes") {
            input.value = dinnerData[input.name] ? dinnerData[input.name] : "";
          } else {
            input.value = dinnerData?.[input.name] ? dinnerData[input.name] : 0;
          }
        }
      });

      this.lunchSalesInput.find(i => i.name == "payment").value =
        parseInt(this.getReportDetails?.lunch_payment) ?? null;
      this.daySalesInput.find(i => i.name == "payment").value =
        parseInt(this.getReportDetails?.dinner_payment) ?? null;

      this.cashSales = this.getReportDetails?.cash_sales
        ? parseInt(this.getReportDetails?.cash_sales)
        : 0;
      this.isReported = this.getReportDetails?.daily_reports[0]?.is_reported;

      this.sharedAccountId = parseInt(lunchData?.["shared_account_id"]);

      this.lunchSalesInput.push({
        id: lunchData?.id,
        isReported: this.getReportDetails?.daily_reports[0]?.is_reported,
        update: "Reported"
      });

      this.daySalesInput.push({
        id: dinnerData?.id,
        isReported: dinnerData?.["is_reported"],
        update: "Reported"
      });
    },
    submitReport(isReported) {
      this.pageLoading = true;
      let params = {
        shop_id: this.getShop.id,
        shared_account_id: this.sharedAccountId,
        is_reported: isReported,
        cash_sales: this.cashSales,
        report_date: this.reportDate.format("YYYY-MM-DD")
      };

      let dinnerInfoData = this.daySalesInput.find(i => i.update == "Reported");
      if (dinnerInfoData?.id) {
        this.dinnerSales.id = dinnerInfoData.id;
      }

      let lunchInfoData = this.lunchSalesInput.find(
        i => i.update == "Reported"
      );
      if (lunchInfoData?.id) {
        this.lunchSales.id = lunchInfoData.id;
      }

      if (this.dinnerSales?.food_sales > 0) {
        params.dinner_sales = this.dinnerSales;
      }
      if (this.lunchSales?.food_sales > 0) {
        params.lunch_sales = this.lunchSales;
      }

      this.updateDailyReportForm(params)
        .then(() => {
          this.$router.push({ name: "drList" });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
